var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    attrs: {
      headers: _vm.headers,
      items: _vm.deviceSizesFiltered,
      "sort-by": "id",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function() {
          return [
            _c(
              "v-toolbar",
              { attrs: { flat: "" } },
              [
                _c("v-toolbar-title", [_vm._v("Device Preview Management")]),
                _c("v-divider", {
                  staticClass: "mx-4",
                  attrs: { inset: "", vertical: "" }
                }),
                _c("v-spacer"),
                _c(
                  "v-dialog",
                  {
                    attrs: { persistent: "", "max-width": "500px" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "mb-2",
                                    attrs: { color: "primary", dark: "" }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _vm._v(
                                  "\n            Add New Device\n          "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.dialogAdd,
                      callback: function($$v) {
                        _vm.dialogAdd = $$v
                      },
                      expression: "dialogAdd"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", [
                          _c("span", { staticClass: "text-h5" }, [
                            _vm._v("New Device")
                          ])
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-container",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "6", md: "4" }
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: { label: "Device" },
                                          model: {
                                            value: _vm.newItem.device,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.newItem,
                                                "device",
                                                $$v
                                              )
                                            },
                                            expression: "newItem.device"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "6", md: "4" }
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: { label: "height" },
                                          model: {
                                            value: _vm.newItem.sizeY,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.newItem,
                                                "sizeY",
                                                $$v
                                              )
                                            },
                                            expression: "newItem.sizeY"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "6", md: "4" }
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: { label: "width" },
                                          model: {
                                            value: _vm.newItem.sizeX,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.newItem,
                                                "sizeX",
                                                $$v
                                              )
                                            },
                                            expression: "newItem.sizeX"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "blue darken-1",
                                  text: "",
                                  disabled: _vm.loadingAddDevice
                                },
                                on: { click: _vm.closeAdd }
                              },
                              [_vm._v("\n              Cancel\n            ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "blue darken-1",
                                  text: "",
                                  loading: _vm.loadingAddDevice,
                                  disabled: _vm.loadingAddDevice
                                },
                                on: { click: _vm.saveNewDevice }
                              },
                              [_vm._v("\n              Save\n            ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-dialog",
                  {
                    attrs: { persistent: "", "max-width": "500px" },
                    model: {
                      value: _vm.dialogEdit,
                      callback: function($$v) {
                        _vm.dialogEdit = $$v
                      },
                      expression: "dialogEdit"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", [
                          _c("span", { staticClass: "text-h5" }, [
                            _vm._v("Edit Device")
                          ])
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-container",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "6", md: "4" }
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: { label: "Device" },
                                          model: {
                                            value: _vm.editedItem.device,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.editedItem,
                                                "device",
                                                $$v
                                              )
                                            },
                                            expression: "editedItem.device"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "6", md: "4" }
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: { label: "height" },
                                          model: {
                                            value: _vm.editedItem.sizeY,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.editedItem,
                                                "sizeY",
                                                $$v
                                              )
                                            },
                                            expression: "editedItem.sizeY"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "6", md: "4" }
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: { label: "width" },
                                          model: {
                                            value: _vm.editedItem.sizeX,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.editedItem,
                                                "sizeX",
                                                $$v
                                              )
                                            },
                                            expression: "editedItem.sizeX"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "blue darken-1",
                                  text: "",
                                  disabled: _vm.loadingEdit
                                },
                                on: { click: _vm.closeEdit }
                              },
                              [_vm._v("\n              Cancel\n            ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "blue darken-1",
                                  text: "",
                                  loading: _vm.loadingEdit,
                                  disabled: _vm.loadingEdit
                                },
                                on: { click: _vm.saveEdit }
                              },
                              [_vm._v("\n              Save\n            ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-dialog",
                  {
                    attrs: { persistent: "", "max-width": "500px" },
                    model: {
                      value: _vm.dialogDelete,
                      callback: function($$v) {
                        _vm.dialogDelete = $$v
                      },
                      expression: "dialogDelete"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", { staticClass: "text-h5" }, [
                          _vm._v(
                            "\n            Are you sure you want to delete this item?\n          "
                          )
                        ]),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "blue darken-1",
                                  text: "",
                                  disabled: _vm.loadingDelete
                                },
                                on: { click: _vm.closeDelete }
                              },
                              [_vm._v("\n              Cancel\n            ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "blue darken-1",
                                  text: "",
                                  loading: _vm.loadingDelete,
                                  disabled: _vm.loadingDelete
                                },
                                on: { click: _vm.deleteItemConfirm }
                              },
                              [_vm._v("\n              OK\n            ")]
                            ),
                            _c("v-spacer")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "item.actions",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-icon",
              {
                staticClass: "mr-2",
                attrs: { small: "" },
                on: {
                  click: function($event) {
                    return _vm.editItem(item)
                  }
                }
              },
              [_vm._v("\n      mdi-pencil\n    ")]
            ),
            _c(
              "v-icon",
              {
                attrs: { small: "" },
                on: {
                  click: function($event) {
                    return _vm.deleteItem(item)
                  }
                }
              },
              [_vm._v("\n      mdi-delete\n    ")]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }