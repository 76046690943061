<script>
import { nowwService } from '@/shared/services'

export default {
  name: 'DevicePreview',
  data () {
    return {
      dialogAdd: false,
      loadingAddDevice: false,
      dialogEdit: false,
      loadingEdit: false,
      dialogDelete: false,
      loadingDelete: false,
      headers: [
        {
          text: 'device',
          align: 'start',
          value: 'device'
        },
        { text: 'id', align: 'start', value: 'id' },
        { text: 'width', align: 'start', value: 'sizeX' },
        { text: 'height', align: 'start', value: 'sizeY' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      deviceSizes: [],
      editedItem: {
        device: '',
        id: 0,
        sizeX: 0,
        sizeY: 0
      },
      defaultItem: {
        device: '',
        id: 0,
        sizeX: 0,
        sizeY: 0
      },
      newItem: {
        device: '',
        id: 0,
        sizeX: 0,
        sizeY: 0
      },
      deleteId: undefined
    }
  },
  computed: {
    deviceSizesFiltered () {
      return this.deviceSizes.filter(({ omitFromDropDown }) => !omitFromDropDown)
    }
  },
  watch: {
    dialogAdd (val) {
      val || this.closeAdd()
    },
    dialogEdit (val) {
      val || this.closeEdit()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  created () {
    this.getDeviceOptions()
  },
  methods: {
    async getDeviceOptions () {
      const res = await nowwService.getSelectDeviceSize()
      if (res.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: res.errorResponse,
          color: 'error'
        })
      }

      this.deviceSizes = res
    },
    async addDeviceOption () {
      const res = await nowwService.insertDeviceSize({
        device: this.newItem.device,
        width: parseInt(this.newItem.sizeX, 10),
        height: parseInt(this.newItem.sizeY, 10)
      })
      if (res.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: res.errorResponse,
          color: 'error'
        })
      }
    },
    async editDeviceOption () {
      const res = await nowwService.updateDeviceSize({
        id: this.editedItem.id,
        device: this.editedItem.device,
        width: parseInt(this.editedItem.sizeX, 10),
        height: parseInt(this.editedItem.sizeY, 10)
      })
      if (res.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: res.errorResponse,
          color: 'error'
        })
      }
    },
    async deleteDeviceOption () {
      const res = await nowwService.deleteDeviceSize({
        id: this.deleteId
      })
      if (res.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: res.errorResponse,
          color: 'error'
        })
      }
    },
    async saveNewDevice () {
      this.loadingAddDevice = true
      await this.addDeviceOption()
      await this.getDeviceOptions()
      this.loadingAddDevice = false
      this.closeAdd()
    },
    async saveEdit () {
      this.loadingEdit = true
      await this.editDeviceOption()
      await this.getDeviceOptions()
      this.loadingEdit = false
      this.closeEdit()
    },
    async deleteItemConfirm () {
      this.loadingDelete = true
      await this.deleteDeviceOption()
      await this.getDeviceOptions()
      this.loadingDelete = false
      this.closeDelete()
    },
    editItem (item) {
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true
    },
    deleteItem (item) {
      this.deleteId = item.id
      this.dialogDelete = true
    },
    closeEdit () {
      this.dialogEdit = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    closeAdd () {
      this.dialogAdd = false
      this.$nextTick(() => {
        this.newItem = Object.assign({}, this.defaultItem)
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.deleteId = undefined
      })
    }
  }
}
</script>

<template>
  <v-data-table
    :headers="headers"
    :items="deviceSizesFiltered"
    sort-by="id"
    disable-pagination
    hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Device Preview Management</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        />
        <v-spacer />
        <v-dialog
          v-model="dialogAdd"
          persistent
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Add New Device
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">New Device</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="newItem.device"
                      label="Device"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="newItem.sizeY"
                      label="height"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="newItem.sizeX"
                      label="width"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                :disabled="loadingAddDevice"
                @click="closeAdd"
              >
                Cancel
              </v-btn>

              <v-btn
                color="blue darken-1"
                text
                :loading="loadingAddDevice"
                :disabled="loadingAddDevice"
                @click="saveNewDevice"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogEdit"
          persistent
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Edit Device</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.device"
                      label="Device"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.sizeY"
                      label="height"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.sizeX"
                      label="width"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                :disabled="loadingEdit"
                @click="closeEdit"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loadingEdit"
                :disabled="loadingEdit"
                @click="saveEdit"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          persistent
          max-width="500px"
        >
          <v-card>
            <v-card-title class="text-h5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                :disabled="loadingDelete"
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loadingDelete"
                :disabled="loadingDelete"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>
